import React, { Component, useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import axios from "axios";
import { decrypt } from './utils/cryptoUtils';
import { messaging } from './firebase'; // Import Firebase messaging
import { getToken } from 'firebase/messaging'; // Import getToken
// import { getFirestore, collection, query, where, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore'; // Import Firestore functions
import { getFirestore, doc, setDoc, getDoc, deleteDoc, collection, query, where, getDocs } from "firebase/firestore";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

const App = (props) => {
    const [state, setState] = useState({});
    const [userId, setUserId] = useState(null);
    const location = useLocation();

    useEffect(() => {
        onRouteChanged();
        const token = localStorage.getItem("token");

        axios.get(baseUrl + `me`, { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {
                const decryptedData = decrypt(res.data.data);
                const id = decryptedData._id;
                setUserId(id);

                if (decryptedData.user_type === "Admin") {
                    setState({
                        ...state,
                        usertype: "Admin",
                        userAname: decryptedData.Name,
                        userId: id,
                        dashboard: true,
                        earning: true,
                        allAdmins: true,
                        newAdmin: true,
                        allUsers: true,
                        sitesettings: true,
                        newUser: true,
                        pendingKyc: true,
                        completedKyc: true,
                        rejectKyc: true,
                        allChallenges: true,
                        completedChallenges: true,
                        conflictChallenges: true,
                        cancelledChallenges: true,
                        runningChallenges: true,
                        newChallenge: true,
                        penaltyBonus: true,
                        depositHistory: true,
                        bonusHistory: true,
                        withdrawlHistory: true,
                        allWithdrawlRequests: true,
                        allDepositRequests: true,
                        pages: true,
                        bonusReport: true,
                        penaltyReport: true,
                        withdrawalReport: true,
                        depositReport: true,
                        dropChallenges: true,
                    });
                } else if (decryptedData.user_type === "Agent") {
                    const permissions = decryptedData.Permissions || [];
                    setState({
                        ...state,
                        usertype: "Agent",
                        userAname: decryptedData.Name,
                        userId: id,
                        dashboard: permissions[0]?.Status || false,
                        earning: permissions[1]?.Status || false,
                        allAdmins: permissions[2]?.Status || false,
                        newAdmin: permissions[3]?.Status || false,
                        allUsers: permissions[4]?.Status || false,
                        newUser: permissions[5]?.Status || false,
                        pendingKyc: permissions[6]?.Status || false,
                        completedKyc: permissions[7]?.Status || false,
                        rejectKyc: permissions[8]?.Status || false,
                        allChallenges: permissions[9]?.Status || false,
                        completedChallenges: permissions[10]?.Status || false,
                        conflictChallenges: permissions[11]?.Status || false,
                        cancelledChallenges: permissions[12]?.Status || false,
                        runningChallenges: permissions[13]?.Status || false,
                        newChallenge: permissions[14]?.Status || false,
                        penaltyBonus: permissions[15]?.Status || false,
                        depositHistory: permissions[16]?.Status || false,
                        withdrawlHistory: permissions[17]?.Status || false,
                        allWithdrawlRequests: permissions[18]?.Status || false,
                        allDepositRequests: permissions[19]?.Status || false,
                        pages: permissions[20]?.Status || false,
                        bonusHistory: permissions[21]?.Status || false,
                        bonusReport: permissions[22]?.Status || false,
                        withdrawalReport: permissions[23]?.Status || false,
                        depositReport: permissions[24]?.Status || false,
                        penaltyReport: permissions[25]?.Status || false,
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array means this runs once after the initial render

    useEffect(() => {
        if (userId) {
            requestPermission(userId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]); // This effect runs whenever userId changes

    const requestPermission = async (currentUserId) => {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const fcmToken = await getToken(messaging, {
                vapidKey: "BMoNJVCFyR532EG_oqnNA14xQJSmbttjNttp7E27a-nE_z7KHSFUi5PM-xfPyeg1vgpNHipzn8OJSrip2nt8GAo",
            });

            console.log("FCM Token:", fcmToken);
            const userType = state.usertype === "Admin" ? "admin" : "admin"; // Determine user type

            // Save the token to Firestore
            saveTokenToFirestore(fcmToken, currentUserId, userType);
        } else if (permission === "denied") {
            alert("You denied the notification permission.");
        }
    };

    const saveTokenToFirestore = async (token, currentUserId, userType) => {
        if (!currentUserId || !userType || !token) {
            console.log("Invalid data: Missing userId, userType, or token. Not saving.");
            return;
        }

        try {
            const db = getFirestore();
            const userDocRef = doc(db, "fcmTokens", currentUserId);

            // Check if the token exists for another user
            const tokensCollection = collection(db, "fcmTokens");
            const tokenQuery = query(tokensCollection, where("token", "==", token));
            const tokenQuerySnapshot = await getDocs(tokenQuery);

            for (const docSnapshot of tokenQuerySnapshot.docs) {
                const existingUserId = docSnapshot.data().userId;
                if (existingUserId !== currentUserId) {
                    console.log(`Token already exists for another user (${existingUserId}). Deleting old entry.`);
                    await deleteDoc(doc(db, "fcmTokens", existingUserId));
                }
            }

            // Check if the user already has a different token stored
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const existingToken = userDoc.data().token;
                if (existingToken === token) {
                    console.log("Token already exists for this user. No update needed.");
                    return;
                } else {
                    console.log("New token detected. Updating the token for user:", currentUserId);
                }
            }

            // Save or update the user's token
            await setDoc(userDocRef, { token, userId: currentUserId, userType });
            console.log("Token saved/updated successfully for user:", currentUserId);

        } catch (error) {
            console.error("Error saving/updating token to Firestore:", error);
        }
    };

    const onRouteChanged = () => {
        window.scrollTo(0, 0);
        const fullPageLayoutRoutes = ['/user-pages/login-1', '/user-pages/login-2', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page', '/landing', "/login", "/register", "/adminlogin", "/home", "/profile", "/help", "/Deposit", "/", "/Homepage/pOPULAR", "/KYC/update-pan", "/KYC/update-aadhar", "/Games"
            , "/Referral-history", `/landing/:id`, "/wallet", "/support", "/Withdrawopt", "/Addcase", "/Addfunds", "/Notification", "/refer", "/transaction", "//transaction-history", "/web", "/return", "/redeem/refer", "/transaction-history"];
        for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
            if (props.location.pathname === fullPageLayoutRoutes[i]) {
                setState({
                    ...state,
                    isFullPageLayout: true
                });
                document.querySelector('.page-body-wrapper')?.classList.add('full-page-wrapper');
                break;
            } else {
                setState({
                    ...state,
                    isFullPageLayout: false
                });
                document.querySelector('.page-body-wrapper')?.classList.remove('full-page-wrapper');
            }
        }
        if (props.location.pathname.split("/")[1] === 'landing' || props.location.pathname.split("/")[1] === 'viewgame1') {
            setState({
                ...state,
                isFullPageLayout: true
            });
            document.querySelector('.page-body-wrapper')?.classList.add('full-page-wrapper');
        }
    }

    useEffect(() => {
        onRouteChanged();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    let navbarComponent = !state.isFullPageLayout ? <Navbar
        usertype={state.usertype} userAname={state.userAname}
    /> : '';
    let sidebarComponent = !state.isFullPageLayout ? <Sidebar
        usertype={state.usertype}
        dashboard={state.dashboard}
        earning={state.earning}
        allAdmins={state.allAdmins}
        newAdmin={state.newAdmin}
        sitesettings={state.sitesettings}
        allUsers={state.allUsers}
        newUser={state.newUser}
        pendingKyc={state.pendingKyc}
        completedKyc={state.completedKyc}
        rejectKyc={state.rejectKyc}
        allChallenges={state.allChallenges}
        completedChallenges={state.completedChallenges}
        conflictChallenges={state.conflictChallenges}
        cancelledChallenges={state.cancelledChallenges}
        runningChallenges={state.runningChallenges}
        newChallenge={state.newChallenge}
        penaltyBonus={state.penaltyBonus}
        depositHistory={state.depositHistory}
        bonusHistory={state.bonusHistory}
        withdrawlHistory={state.withdrawlHistory}
        allWithdrawlRequests={state.allWithdrawlRequests}
        allDepositRequests={state.allDepositRequests}
        pages={state.pages}
        bonusReport={state.bonusReport}
        penaltyReport={state.penaltyReport}
        withdrawalReport={state.withdrawalReport}
        depositReport={state.depositReport}
        dropChallenges={state.dropChallenges}
    /> : '';
    let footerComponent = !state.isFullPageLayout ? <Footer /> : '';

    return (
        <div className="container-scroller">
            {sidebarComponent}
            <div className="container-fluid page-body-wrapper">
                {navbarComponent}
                <div className="main-panel">
                    <div className="content-wrapper" style={{ backgroundColor: "rgba(0, 27, 11, 0.734)" }}>
                        <AppRoutes />
                    </div>
                    {footerComponent}
                </div>
            </div>
        </div>
    );
}

export default withRouter(App);