import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBr9J0uuMkns4IujhNVxzphQSAfhub_kgQ",
    authDomain: "notification-92bff.firebaseapp.com",
    projectId: "notification-92bff",
    storageBucket: "notification-92bff.firebasestorage.app",
    messagingSenderId: "904110358137",
    appId: "1:904110358137:web:1480e16ba2e7cfbea5dea8",
    measurementId: "G-4MD9L0FJ37"
  };

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
