import { useState, useEffect } from "react";
import io from "socket.io-client";
import { useHistory } from 'react-router-dom';

const beckendWp = process.env.REACT_APP_BACKEND_WHATS_APP;

const socket = io(beckendWp, {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 2000
});

export default function WhatsAppLogin() {
    const history = useHistory();

    const [qrCode, setQrCode] = useState(null);
    const [status, setStatus] = useState("Checking status...");
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        function handleQr(data) {
            setQrCode(data.qr);
            setStatus("Scan the QR Code");
            setIsAuthenticated(false);
        }

        function handleAuthenticated() {
            setStatus("✅ Authenticated! WhatsApp Connected");
            setQrCode(null);
            setIsAuthenticated(true);
        }

        function handleLogout() {
            setStatus("❌ Disconnected! Scan again.");
            setQrCode(null);
            setIsAuthenticated(false);
        }

        // Socket event listeners
        socket.on("qr", handleQr);
        socket.on("authenticated", handleAuthenticated);
        socket.on("logout", handleLogout);

        // Check login status on mount
        fetch(`${beckendWp}/status`)
            .then(res => res.json())
            .then(data => {
                if (data.status === "connected") {
                    handleAuthenticated();
                } else {
                    fetch(`${beckendWp}/get-qr`)
                        .then(res => res.json())
                        .then(data => {
                            if (data.qr) {
                                handleQr(data);
                            } else {
                                setStatus("❌ QR Code not available. Please try again.");
                            }
                        })
                        .catch(() => setStatus("❌ Failed to fetch QR Code."));
                }
            })
            .catch(() => setStatus("❌ Failed to check login status."));

        // Cleanup socket listeners on unmount
        return () => {
            socket.off("qr", handleQr);
            socket.off("authenticated", handleAuthenticated);
            socket.off("logout", handleLogout);
        };
    }, []);

    const handleLogoutClick = async () => {
        try {
            const response = await fetch(`${beckendWp}/logout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const data = await response.json();
            if (data.success) {
                setStatus("❌ Logged out successfully!");
                setQrCode(null);
                setIsAuthenticated(false);
            }
        } catch (error) {
            console.error("Error logging out:", error);
            setStatus("⚠️ Logout failed. Try again.");
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
            <h1 className="text-2xl mb-4">{status}</h1>
            {qrCode && <img src={qrCode} alt="QR Code" className="w-64" />}
            {isAuthenticated && (
                <button 
                    onClick={handleLogoutClick} 
                    className="mt-4 px-4 py-2 bg-danger text-white rounded">
                    Logout
                </button>
            )}
        </div>
    );
}
